<template>
  <div id="select-frame">
    <div class="head">
      <h1>{{ $t("COMMON.SELECT_FRAME") }}</h1>
    </div>
    <div class="container">
      <div class="frames mt-4">
        <div class="row">
          <div
            class="col-5 frame left"
            :class="[{ show: show }, { selected: frameSelected === 1 }]"
          >
            <img
              @click="selectFrame(1)"
              src="@/assets/media/frames/1.png"
              alt=""
            />
          </div>
          <div class="col-2"></div>
          <div
            class="col-5 frame right"
            :class="[{ show: show }, { selected: frameSelected === 2 }]"
          >
            <img
              @click="selectFrame(2)"
              src="@/assets/media/frames/frame.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <b-button class="btn-success" @click="onSubmit">{{
          $t("COMMON.SUBMIT")
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    ...mapMutations({
      SET_FRAME: "SET_FRAME",
    }),
    selectFrame(frameNum) {
      this.SET_FRAME(frameNum);
    },
    onSubmit() {
      this.$router.push({
        name: "Event Photo",
        params: {
          eventId: this.$route.params.eventId,
          doctorId: this.$route.params.doctorId,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      frameSelected: "frameSelected",
    }),
  },
};
</script>

<style lang="scss" scoped>
#select-frame {
  .head {
    background-color: white;
    padding: 15px;
    text-align: center;
    h1 {
      font-weight: 500;
      font-size: 24px;
      color: #3c4eba;
      font-family: fantasy;
    }
  }

  .frames {
    .frame {
      display: flex;
      justify-content: center;
      background-color: white;
      padding: 15px;
      transition: 1.5s transform ease-in, 2s opacity ease-in;
      height: 520px;
      width: 292.5px;

      img {
        width: 100%;
        cursor: pointer;
      }

      &:hover,
      &.selected {
        outline: 10px solid rgba(60, 78, 186, 0.5);
      }

      &.left {
        transform: translateX(-150%);
        opacity: 0;

        &.show {
          transform: translateX(0);
          opacity: 1;
        }
      }

      &.right {
        transform: translateX(250%);
        opacity: 0;

        &.show {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }
}
</style>
